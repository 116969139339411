import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';

const App = () => {
  const [conversation, setConversation] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [loadingPass, setLoadingPass] = useState(false);
  const [error, setError] = useState(null);
  const [modelExists, setModelExists] = useState(null);
  const location = useLocation();

  const isDevelopment = process.env.NODE_ENV === 'development';
  const { inputModel } = useParams();  // inputModel is obtained from useParams()

  const [baseURL, setBaseURL] = useState(() => {
    if (isDevelopment) {
      return "http://127.0.0.1:8000";
    }
    else {
      return "https://api.clef.symphonicstrategies.com";
    }
  });

  const addMessage = (role, content) => {
    const newMessage = { role, content };
    setConversation(prevConversation => [...prevConversation, newMessage]);
  };

  const getTokenFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('token') || false;
  };

  const handleLogin = async () => {
    if (isDevelopment) {
      setAuthenticated(true); // Automatically authenticate in development mode
      return;
    } 
    else {
      try {
        setLoadingPass(true);
        const response = await fetch('https://api.clef.symphonicstrategies.com/clef/v1/access/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ password: password }) // Send the password to the API
        });

        setLoadingPass(false);

        if (response.ok) {
          setAuthenticated(true);
        } else {
          setError('Invalid password');
        }
      } catch (error) {
        setError('Error during authentication');
        setLoadingPass(false);
      }
    }
  };

  const checkModelExists = async () => {
    if (process.env.NODE_ENV === 'development') {
      setModelExists(true);
    } else {
      try {
        const response = await fetch(`https://api.clef.symphonicstrategies.com/clef/chat/${inputModel}/`);
        if (response.ok) {
          setModelExists(true); // Model exists
        } else {
          setModelExists(false); // Model does not exist
        }
      } catch (error) {
        setModelExists(false);
        console.error('Error checking model existence:', error);
      }
    }
  };

  useEffect(() => {
    checkModelExists();
    const token = getTokenFromUrl();
    async function checkToken() {
      if (token) {
        if (process.env.NODE_ENV === 'development') {
          setAuthenticated(true)
          console.log(token)
        }
        else {
          const response = await fetch('https://api.clef.symphonicstrategies.com/clef/v1/bypass/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: token }) // Send the password to the API
          });
  
          if (response.ok) {
            setAuthenticated(true);
          } else {
            setError('Invalid token');
          }
        }
      }
    }
    checkToken();
  }, [inputModel]);

  if (modelExists === null) {
    return <div>Loading...</div>; // Display loading until model existence is determined
  }

  if (!modelExists) {
    return <div>404 Not Found</div>; // Display 404 if the model does not exist
  }

  if (!authenticated) {
    return (
      <div className="login-container">
        <h2>Login</h2>
        <input
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="password-field"
        />
        <button onClick={handleLogin} className="login-button" disabled={loadingPass}>
          {loadingPass ? 'Sent...' : 'Login'}
        </button>

        {error && <p className="error-message">{error}</p>}
      </div>
    );
  }

  return (
    <div className="app-container">
      <ChatBox 
        conversation={conversation} 
        onSendMessage={addMessage} 
        setConversation={setConversation}
        inputModel={inputModel}  // Pass inputModel as a prop
        baseURL = {baseURL}
      />
    </div>
  );
};

const ChatBox = ({ conversation, onSendMessage, setConversation, inputModel, baseURL }) => {  // inputModel is now a prop
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef(null);
  const submitButtonRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const resizeTextArea = () => {
    const textarea = textareaRef.current;
    
    if (textarea) {
      const computedStyle = window.getComputedStyle(textarea);
      const maxHeight = parseFloat(computedStyle.maxHeight); // Extract max-height value

      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textarea.scrollHeight > maxHeight) {
        textarea.style.overflowY = 'auto'; // Show scrollbar
      } 
      else {
        textarea.style.overflowY = 'hidden'; // Hide scrollbar
      }
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);
  
  useEffect(() => {
    resizeTextArea();
  }, [input]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Allow newline when Shift + Enter is pressed
        e.preventDefault();
        const textarea = textareaRef.current;
        const caretPosition = textarea.selectionStart;
        const currentText = textarea.value;

        const newText = currentText.slice(0, caretPosition) + "\n" + currentText.slice(caretPosition);

        textarea.value = newText;

        setTimeout(() => {
          textarea.selectionStart = caretPosition + 1;
          textarea.selectionEnd = caretPosition + 1;
        }, 0);
        resizeTextArea();
      } 
      else {
        e.preventDefault();
        submitButtonRef.current.click();
      }
    }
  };

  const handleSend = async () => {
    if (input.trim()) {
      const userMessage = input;

      const updatedConversation = [...conversation, { role: 'user', content: userMessage }];
      onSendMessage('user', userMessage);
      setInput('');

      if (process.env.NODE_ENV === 'development') {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoading(false);
        onSendMessage('assistant', "*This* is a message from Clef__. In Dev. **Nice to see you!**");
      } 
      else {
        setLoading(true); // Show loading icon
        try {
          const response = await fetch(`${baseURL}/clef/chat/${inputModel}/`, {  // Use inputModel here
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: updatedConversation }) // Send the updated conversation
          });
          setLoading(false);

          if (response.body) {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            setConversation(prevConversation => [
              ...prevConversation,
              { role: 'assistant', content: '' }  // Start with an empty assistant message
            ]);
            let result = '';
          
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              const chunk = decoder.decode(value, { stream: true });
              result += chunk;
              setConversation(prevConversation => {
                const updatedConversation = [...prevConversation];
                updatedConversation[updatedConversation.length - 1] = {
                  ...updatedConversation[updatedConversation.length - 1],
                  content: result
                };
                return updatedConversation;
              });
            }
          } else {
            throw new Error('No response body');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      }
    }
  };

  return (
    <div className="chat-box">
      <div className="messages">
        {conversation.map((msg, index) => (
          <div
            key={index}
            className={`message ${msg.role === 'user' ? 'message-user' : 'message-assistant'}`}
          >
            <strong>{msg.role === 'user' ? 'You' : 'Clef'}: </strong>
              <span>
                <Markdown>{msg.content.replace(/_/g, '\\_')}</Markdown>
              </span>
          </div>
        ))}
        {loading && (
          <div className="message message-assistant">
            <strong>Clef:</strong><span className="loading-icon"></span>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-container">
        <textarea
          ref={textareaRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          className="input-field"
          rows={1}
        />
        <button ref={submitButtonRef} onClick={handleSend} className="send-button" disabled={loading || input.trim() === ''}>➤</button>
      </div>
    </div>
  );
};

export default App;