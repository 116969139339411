import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/chat/sym" />} />
        <Route path="/chat/:inputModel" element={<App />} />
        <Route 
          path="*" 
          element={
            <div 
              style={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', 
                fontSize: '24px'
              }}
            >
              This page could not be found. 404
            </div>
          } 
        />
      </Routes>
    </Router>
  </React.StrictMode>
);